import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LoginButton from "./LoginButton";
import Typography from '@material-ui/core/Typography';
import { Paper,Grid, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import { TextField } from '@material-ui/core';


const config = require("./config")

const useStyles = makeStyles((theme) => ({
    
    admin:{
        width:"100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin:0,
        paddingTop: theme.spacing(2),
    },
    header:{
        width:"100%"
    },
    content:{
        // width:"100%",
        // height:"100%",
        padding:theme.spacing(2)
    },
    Liscense:{
        // width:"100%",
        // height:theme.spacing(20),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1),

    },
    cell: {
        borderBottom: "none",
        textAlign: "left",
        width:"50%",
        whiteSpace:"pre-line",
      },
    description:{
        width:"100%",
        marginTop:theme.spacing(1),
    },
    table:{
    }
  }));

export default function Setting(props) {
    
    const classes = useStyles();
    const [metaData, setMetaData] = useState(props.login_data === undefined? {}:props.login_data.meta_data);

    useEffect(() => {
        if (props.login_data!= undefined)
            setMetaData(props.login_data.meta_data);
    }, [props.login_data])

    var handleChange = (key,value)=>{
        let data = metaData;
        data[key] = value;
        setMetaData({...data});
    }

    var addMember = ()=>{
        let data = metaData;
        if (!data.members)
            data.members = [];
        data.members.push({name:"",email:""});
        setMetaData({...data});
    }

    var saveChanges = ()=>{
        //check if all members are valid, at least one member
        if (metaData.members){
            for (let i=0;i<metaData.members.length;i++){
                if (metaData.members[i].name === "" || metaData.members[i].email === ""){
                    alert("Please fill in all the member's name and email");
                    return;
                }
                //check if email is valid
                if (!metaData.members[i].email.includes("@")){
                    alert("Please fill in a valid email");
                    return;
                }

            }
        }else{
            alert("Please add at least one member");
            return;
        }

        //check if team name is filled and if license is selected
        if (metaData.team_name === "" || metaData.license === ""){
            alert("Please fill in all required the fields");
            return;
        }
        
        fetch('/api/save_meta_data', {
            method: 'POST',
            headers: {
            "x-access-token": props.login_data.accessToken,
            "Content-type": "application/json",
            },
            body: JSON.stringify({ 
                meta_data: metaData,
             }),
        })
        .then((res)=>res.json())
        .then((body)=>{
            if (body.success){
                let login_data = props.login_data;
                login_data.meta_data = metaData;
                props.set_login_data(login_data);
            }
        })

    }

    if (!props.login_data)
    return(<div></div>);

    var license_template=undefined;
    if ( props.login_data.license_templates !== undefined && props.login_data.license_templates[metaData.license]!= undefined){
        license_template = props.login_data.license_templates[metaData.license];
        license_template = license_template.replaceAll("{{ organization }}", metaData.organisation_name||"");
        license_template = license_template.replaceAll("{{ year }}", (new Date).getFullYear());
        license_template = license_template.replaceAll("{{ project }}", metaData.project_name||"");

    }

    return (
    <Grid container justifyContent="center" spacing={2} className={classes.admin}>
        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper elevation={10} className={classes.Liscense}>
                <Grid container spacing={2} alignItems="center" alignContent="space-between" justifyContent="space-between" className={classes.header}>
                    <Grid item xs={7}>
                        <h2>About This Page</h2>
                    </Grid>
                    <Grid item xs={5} style={{textAlign: "right"}}>
                        <Button onClick={saveChanges} size="small" variant="outlined" color='secondary' disabled={metaData===props.login_data.meta_data}>Save All Changes</Button>
                    </Grid>
                </Grid>
                <Typography> 
                    This page records the team information, contacts and license information for your team and your submissions. 
                    The "*" items are required for submission evaluation.
                </Typography>

                <Typography>
                    <b>Click the "Save All Changes" button to save any changes you made.</b>
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper elevation={10} className={classes.Liscense}>
                <h2>Team Information</h2>
                <Typography>
                    The information regarding your team and submissions. 
                    Each team should have at least one member.
                    We will reach you or sending announcements through the provided email for each team member.
                </Typography>


                <Table size="small" >
                        <TableBody>

                        <TableRow>
                            <TableCell className={classes.cell}>Team Name:* </TableCell>
                            <TableCell className={classes.cell}>
                            <Input
                                color={"secondary"}
                                value = {metaData.team_name|| ''}
                                onChange={(event)=>{handleChange("team_name",event.target.value)}}
                            >
                            </Input>
                            </TableCell>
                        </TableRow>
                        

                        <TableRow>
                                <TableCell className={classes.cell}>Affiliations: </TableCell>
                                <TableCell className={classes.cell}>
                                    <Input
                                        color={"secondary"}
                                        value = {metaData.affiliation|| ''}
                                        onChange={(event)=>{handleChange("affiliation",event.target.value)}}
                                    >
                                    </Input>
                                </TableCell>
                                
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.cell}>Countries: </TableCell>
                                <TableCell className={classes.cell}>
                                    <Input
                                        color={"secondary"}
                                        value = {metaData.country|| ''}
                                        onChange={(event)=>{handleChange("country",event.target.value)}}
                                    >
                                    </Input>
                                </TableCell>
                                
                            </TableRow>
                            <TableRow>
                            <TableCell className={classes.cell}>
                                Team Members and Contacts:* 
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <Button onClick={addMember} size="small" variant="outlined" color='secondary'>Add Member</Button>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                        {/* example data in metaData.members=[{name:zhe,email:zhe@xx.xx}]
                         inputs to edit the name and email for each member, then with a button to add member
                         */}
                    <Table size="small" >
                        <TableBody>
                        
                         {metaData.members && metaData.members.map((member,index)=>{
                                return(
                                    <TableRow key={index}>
                                        <TableCell className={classes.cell}>
                                            Name: &nbsp;
                                            <Input
                                                label="Name"
                                                color={"secondary"}
                                                value = {member.name|| ''}
                                                onChange={(event)=>{
                                                    let data = metaData;
                                                    data.members[index].name = event.target.value;
                                                    setMetaData({...data})
                                                }}
                                            >
                                            </Input>
                                        </TableCell>
                                        <TableCell className={classes.cell}>
                                            Email: &nbsp;
                                            <Input
                                                label="Email"
                                                type='email'
                                                color={"secondary"}
                                                value = {member.email|| ''}
                                                onChange={(event)=>{
                                                    let data = metaData;
                                                    data.members[index].email = event.target.value;
                                                    setMetaData({...data})
                                                }}
                                            >
                                            </Input>
                                        </TableCell>
                                        {/* with a button to delete the member */}
                                        <TableCell className={classes.cell}>
                                            <Button onClick={()=>{
                                                let data = metaData;
                                                data.members.splice(index,1);
                                                setMetaData({...data})
                                            }} size="small" variant="outlined" color='secondary'>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                         }
                        )}
                        
                        

                        </TableBody>
                    </Table>
                

                

                <Typography>
                    Short description to your team and submissions:
                </Typography>
                <TextField 
                className={classes.description} id="outlined-basic" 
                label="Tell us about your submissions*" 
                variant="outlined" 
                value={metaData.description||''} multiline={true}
                onChange={(event)=>{handleChange("description",event.target.value)}}
                />

            </Paper>
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper elevation={10} className={classes.Liscense}>
                <h2>License</h2>
                <Typography>
                All submissions will be released under an open source license after the competition.

                Select an open source license for your submissions here:
                </Typography>
                <Table size="small" >
                    <TableBody>
                    <TableRow>
                            <TableCell className={classes.cell} >Select License:* </TableCell>
                            <TableCell className={classes.cell} >
                                <Select
                                native
                                color={"secondary"}
                                value={metaData.license|| ''}
                                onChange={(event)=>{handleChange("license",event.target.value)}}
                                >
                                <option aria-label="None" key={0} value={undefined}>Please Select</option>
                                {Object.keys(props.login_data.license_options).map(function(key, index) {
                                    return(<option key={index+1} value={key}>{key}</option>)
                                })}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>Organisation/Author Name: </TableCell>
                            <TableCell className={classes.cell}>
                                <Input
                                    color={"secondary"}
                                    value = {metaData.organisation_name|| ''}
                                    onChange={(event)=>{handleChange("organisation_name",event.target.value)}}
                                >
                                </Input>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>Project Name: </TableCell>
                            <TableCell className={classes.cell}>
                                <Input
                                    color={"secondary"}
                                    value = {metaData.project_name|| ''}
                                    onChange={(event)=>{handleChange("project_name",event.target.value)}}
                                >
                                </Input>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                License Preview:
                             </TableCell>
                            <TableCell className={classes.cell}>{license_template}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

                

            </Paper>
        </Grid>

        
        
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={10} className={classes.content}>
            
            </Paper>
        </Grid> */}
    </Grid>
    )
    
    
}